
import { HIDE_INTERVENTION, SHOW_INTERVENTION } from "../actions";
import { REMOVE_PAGE_STACK } from "../actions/defaultPage";

import { v4 as uuidv4 } from "uuid";

const MenuUtil = {};

MenuUtil.getPaginationStack = (items, maxItemPerRow = 5) => {
    const itemsCopy = items && items.slice();

    const pagePartition = [];

    let lastPoint = 0;
    let itemSpanCounter = 0;
    let indexTracker = 0;

    if (itemsCopy && itemsCopy.length > 0) {
        for (let index = 0; index < itemsCopy.length; index++) {
            const item = itemsCopy[index];
            const partitionMultiplier = pagePartition.length + 1;

            const columnSpan = item.columnSpan || 1;
            if (columnSpan > 1) {
                const rowPosition = itemSpanCounter % maxItemPerRow;
                if (rowPosition + columnSpan > maxItemPerRow) {
                    itemSpanCounter += (maxItemPerRow - rowPosition) + columnSpan;
                } else {
                    itemSpanCounter += columnSpan;
                }
            } else {
                itemSpanCounter += columnSpan;
            }
            const exactlyFit = itemSpanCounter === partitionMultiplier * (maxItemPerRow * 4);
            if (itemSpanCounter > partitionMultiplier * (maxItemPerRow * 4)) {
                const nextIndex = exactlyFit ? index : indexTracker;
                const partitionObject = {
                    start: lastPoint === 0 ? lastPoint : lastPoint + 1,
                    end: nextIndex,
                };
                lastPoint += nextIndex;
                pagePartition.push(partitionObject);
            }
            indexTracker = index;
        }
    }
    return pagePartition;
};

MenuUtil.getAlternativePaginationSetup = (items) => {
    const maxItemPerRow = 6;
    const paginationStack = MenuUtil.getPaginationStack(items, maxItemPerRow);
    return { shouldBeSinglePage: paginationStack.length === 0, alternativePaginationStack: paginationStack };
};

MenuUtil.getMenuItemActionMapping = (productInformationList, defaultButtonTemplate) => {
    if (productInformationList && productInformationList.length > 0) {
        const actionMapping = productInformationList.map((productInfo, index) => {
            const {
                productName, menuItemName, imagePath, title, description, columnSpan,
            } = productInfo;

            const widthLength = columnSpan ? columnSpan * 80 + ((columnSpan - 1) * 10) : 80;
            const buttonProps = {
                transaction: productName,
                widthLength,
                imagePath,
                productName,
                index,
                menuItemName,
            };

            return {
                title,
                imagePath,
                description,
                menuItemName,
                transaction: productName,
                component: (additionalProp) => defaultButtonTemplate({ ...buttonProps, ...additionalProp }),
                renderCustomComponent: (customButtonProps) => defaultButtonTemplate(customButtonProps),
            };
        });
        return actionMapping;
    }
    return [];
};

MenuUtil.getCustomItemProps = (mappingItem, item, index, widthLength) => {
    const [customName, customButtonText, customHeading, customDesciption] = item.name.split("|");
    return {
        widthLength,
        index: 1000 + index,
        imagePath: item.image || mappingItem.imagePath,
        transaction: customName,
        pageDetails: {
            name: customButtonText,
            title: customHeading || mappingItem.title,
            description: customDesciption || mappingItem.description,
            productName: customName || mappingItem.productName,
        },
    };
};

MenuUtil.getMenuItemPageIndices = (props) => {
    const {
        itemPageIndex, paginationStack, currentPageStack, alternativePaginationSetup,
    } = props;

    let startIndex = 0;
    let endIndex = Infinity;

    const { shouldBeSinglePage, alternativePaginationStack } = alternativePaginationSetup;

    const paginationStackToUse = shouldBeSinglePage ? alternativePaginationStack : paginationStack;

    if (paginationStackToUse.length > 0) {
        if (currentPageStack && currentPageStack.start !== null) {
            startIndex = currentPageStack.start;
            endIndex = currentPageStack.end;
        } else {
            const { end } = paginationStackToUse[itemPageIndex - 1];
            startIndex = end + 1;
        }
    }
    return { startIndex, endIndex };
};

MenuUtil.checkIfShouldSkipEServicesPage = (state) => {
    const { pathname } = window.location;
    const { skipEServicesMenuPage } = state.config;

    const defaultProductMappingByPath = {
        "/kiosk": "KIOSK",
        "/giftcards": "GIFT_CARDS",
        "/cancel": "CANCELLATION",
        "/refund": "REFUND",
        default: "KIOSK",
    };

    const redirectPaths = Object.keys(defaultProductMappingByPath).filter((key) => key !== "default");
    const shouldRedirectDefaultProduct = redirectPaths.includes(pathname) || skipEServicesMenuPage;

    const configIsLoading = typeof skipEServicesMenuPage === "undefined";
    let skipEServicesPage = configIsLoading ? skipEServicesMenuPage : shouldRedirectDefaultProduct;
    const pathNameDirectedToMainMenu = pathname === "/main";

    const defaultProduct = defaultProductMappingByPath.default;

    if (pathname === "/etender") {
        const { currentPageDetailsIndex } = state.defaultPage;
        // eslint-disable-next-line no-const-assign
        skipEServicesPage = currentPageDetailsIndex === 0;
    }
    return {
        skipEServicesPage: pathNameDirectedToMainMenu ? false : skipEServicesPage,
        transactionKey: defaultProductMappingByPath[pathname] || defaultProduct,
    };
};

MenuUtil.getMenuTitle = (menuItemProps, selectedMenuStack) => {
    const { menuItemIndex, menuItemStack, menuTitle } = menuItemProps;
    const selectedMenuId = selectedMenuStack[selectedMenuStack.length - 1];
    const findMenu = (menu) => menu.menuId === selectedMenuId;

    const previousMenuDetails = menuItemIndex > 0 ? menuItemStack[menuItemIndex - 1] : [];
    const preivousMenu = previousMenuDetails && previousMenuDetails.find(findMenu);

    return preivousMenu ? preivousMenu.name : menuTitle || "eServices";
};

MenuUtil.makeItComboIntervention = ({ dispatch, message, history, item, location, orderID }) => {
    const handleYes = () => {
        const state = {
            item,
        };
        const hotFoodComboID = uuidv4();
        dispatch({ type: "SET_HOTFOOD_COMBO_ID", orderID, hotFoodComboID, productNumber: item.productId });
        dispatch({ type: "HFC_MAIN_PRODUCT_ID", parentOrderID: orderID, hotFoodComboID });
        history.push({ pathname: "hotfood-combo", search: location.search, state });
        dispatch({ type: HIDE_INTERVENTION });
    };

    const handleNo = () => {
        dispatch({ type: REMOVE_PAGE_STACK })
        dispatch({ type: HIDE_INTERVENTION });
    }
    const children = (
        <>
            <p className="title">
                {message}
            </p>
            <div className="actions-container" style={{ width: '550px' }}>
                <button style={{ whiteSpace: 'nowrap' }}
                    onClick={handleNo}
                >
                    No, item only
                </button>
                <button style={{ whiteSpace: 'nowrap' }} onClick={handleYes}>Yes, make it a combo</button>
            </div>
        </>
    );
    dispatch({ type: SHOW_INTERVENTION, children });
}
export default MenuUtil;

import { Helpers } from 'common-functions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExtendedEserviceMenu } from '../../hotFoodCombo.services';
import { getDefaultStoreDetails } from '../hotFoodCombo.utils';


function useGetExtendedEserviceMenu() {
    const { subMenuId } = useSelector((state) => state.hotFoodCombo);
    const dispatch = useDispatch();
    const history = useHistory();
    const { ncrWebServiceUrl, defaultValues } =
        useSelector((state) => state.config);

    const onBackPage = () => {
        // dispatch({ type: REMOVE_PAGE_STACK });
        history.goBack();
    };


    function handleGetExtendedEserviceMenu({ operatorAuthId, menuId }) {
        const storeDetails = Helpers.getStoreDetails();
        const { storeId, operatorId } = getDefaultStoreDetails(defaultValues);
        const requestBody = {
            storePosId: storeDetails.storeId || storeId,
            operatorId: storeDetails.operatorId || operatorId,
            operatorAuthId: operatorAuthId || null,
            menuId: menuId || subMenuId,
        };
        getExtendedEserviceMenu({
            baseURL: ncrWebServiceUrl,
            requestBody,
            dispatch,
            onGoBack: onBackPage,
        });
    }
    return { handleGetExtendedEserviceMenu }
}

export default useGetExtendedEserviceMenu;
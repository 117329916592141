/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import HeadTitle from "../../components/HeadTitle";

import Navigation from "../../components/Navigation";
import {
    REMOVE_PAGE_STACK,
    INITIATE_DEFAULT_PRODUCT,
} from "../../actions/defaultPage";
import HotFoodComboPageItems from "./components/RenderItems";
import HotFoodTab from "./components/HotFoodTab";
import CustomField from "./components/CustomField";
import { isRequiredAuth } from "./helpers/hotFoodCombo.utils";
import OperatorIdPrompt from "../../components/interventions/OperatorIdPrompt";
import useGetExtendedEserviceMenu from "./helpers/hooks/useGetExtendedEserviceMenu";


import "./hot-combo.css";
import usePrefetchGetExtendedEserviceMenu from "./helpers/hooks/usePrefetchGetExtendedEserviceMenu";
import { getUniqueFromArray, isBuc_eesResoluton } from "../../utils/helper";


function HotFoodComboPage() {
    const { state } = useLocation();
    const [title, setTitle] = useState("");
    const [activeTabName, setActiveTabNAme] = useState("");
    const [selectedOption, setSelectedOption] = useState({});
    const [showBarcode, setShowBarcode] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const { handleGetExtendedEserviceMenu } = useGetExtendedEserviceMenu();
    const { handlePrefetchGetExtendedEserviceMenu } = usePrefetchGetExtendedEserviceMenu();

    const { promptOperatorId } = useSelector((state) => state.defaultPage);
    const { isSuccessAuth, subMenuId, tabs, menuItems: items, hideNavigationTab: hideNav, hotFoodPageStack, tabsStack, prefetchedItems, orders } = useSelector((state) => state.hotFoodCombo);

    const { productInformationList } =
        useSelector((state) => state.config);

    const setSubMenuId = (subMenuId) => dispatch({ type: "HFC_SET_SUB_MENU_ID", subMenuId });


    useEffect(() => {
        if (state.item) {
            const { name, extendedMenuItems } = state.item;
            isRequiredAuth({ extendedMenuItems, dispatch, handleGetExtendedEserviceMenu, setSubMenuId });
            const tabName = setTabLabel(extendedMenuItems[0].name);
            setTitle(name);
            setActiveTabNAme(tabName);
            // fetchProductDetails(extendedMenuItems); //for barcode scanning
            dispatch({ type: "HFC_ADD_TAB_STACK", tabs: extendedMenuItems });
            dispatch({ type: "HFC_SET_TABS", tabs: extendedMenuItems });
        }
    }, [state.item]);



    useEffect(() => {
        if (isSuccessAuth) {
            if (state?.item) {
                const extendedMenuItems = state?.item?.extendedMenuItems;
                const menuId = extendedMenuItems[0].menuId;
                const requiredMenus = extendedMenuItems.filter(item => item.requiredAuth);
                dispatch({ type: "HFC_ADD_PAGE_STACK", menuId: extendedMenuItems[0].menuId });
                if (menuId !== requiredMenus[0]?.menuId) {
                    handleGetExtendedEserviceMenu({ menuId });
                }
            }
        }
    }, [isSuccessAuth])

    useEffect(() => {
        if (tabs.length && subMenuId) {
            dispatch({ type: "HFC_ADD_PAGE_STACK", menuId: tabs[0].menuId })
            const isHideNav = tabs.some((item) => item.hideNav);
            dispatch({ type: "HFC_SET_HIDE_NAVIGATION_TABS", hideNavigationTab: isHideNav })
            const selectedTabs = tabs.find((tab) => tab.menuId === subMenuId);
            if (selectedTabs) setSelectedOption(selectedTabs);
        }
    }, [subMenuId, tabs]);

    useEffect(() => {
        if (tabs.length) {
            const tab = tabs[0];
            if (tab && title) {
                handlePrefetchGetExtendedEserviceMenu(tabs);
                const tabName = setTabLabel(tab.name);
                setActiveTabNAme(tabName)
            }
        }
    }, [tabs]);

    useEffect(() => {
        if (!hideNav && tabs.length > 0) handleGetExtendedEserviceMenu({ menuId: tabs[0].menuId });
    }, [hideNav])

    useEffect(() => {
        const uniqueOrders = getUniqueFromArray(orders, 'category');
        if (uniqueOrders.length >= 2) {
            dispatch({ type: REMOVE_PAGE_STACK });
            history.goBack();
        }
    }, [orders])

    useEffect(() => {
        return () => dispatch({ type: 'HFC_RESET_STATE' })
    }, [])
    // for barcode scan
    // const fetchProductDetails = (items) => {
    //     const _item = items.find((item) => isCustomFunction(item.name));
    //     if (_item) {
    //         const [name] = _item.name.split("|");
    //         const productInfo = productInformationList.find(
    //             (product) => product.menuItemName === name,
    //         );
    //         const product = {
    //             title: productInfo.title,
    //             description: productInfo.description,
    //             productName: productInfo.productName,
    //             transaction: name,
    //         };
    //         dispatch({ type: INITIATE_DEFAULT_PRODUCT, ...product });
    //     }
    // };

    function setTabLabel(name = "") {
        if (!name.includes("&")) return name;
        const [index0, description] = name.split("|");
        const productInfo = productInformationList.find((info) => info.menuItemName === index0);
        return description || productInfo?.productName;
    }

    const onBackPage = () => {
        const menuId = hotFoodPageStack[hotFoodPageStack.length - 2];
        if (menuId) {
            const tabStack = tabsStack.find((tab) =>
                tab.find((newtab) => newtab.menuId === menuId),
            );
            dispatch({ type: "HFC_REMOVE_PAGE_STACK" });
            if (tabStack) dispatch({ type: "HFC_SET_TABS", tabs: tabStack });
            return handleGetExtendedEserviceMenu({ menuId });
        }
        dispatch({ type: REMOVE_PAGE_STACK });
        history.goBack();
    };


    function handleTabClick(tab) {
        const tabName = setTabLabel(tab.name);
        setActiveTabNAme(tabName)
        if (isCustomFunction(tab.name)) {
            setShowBarcode(true);
            return;
        } else {
            const menuItems = prefetchedItems.find((item) => item.menuId === tab.menuId);
            setShowBarcode(false);
            if (menuItems) {
                return dispatch({ type: 'HFC_MENU_ITEMS', menuItems: menuItems.menuItems })
            }
            handleGetExtendedEserviceMenu({ menuId: tab.menuId });

        }

        setSubMenuId(tab.menuId);
    }

    const isCustomFunction = (name = "") => name.includes('&scanproduct');

    function truncateTitle(title, tab) {
        const fullTitle = `${title}__${tab}`;
        const maxLength = isBuc_eesResoluton() ? 30 : 40;
        const existingChar = Math.abs(fullTitle.length - maxLength);
        const char = '... - ';
        if (fullTitle.length <= maxLength) return fullTitle.replace('__', " - ");

        const toReplaceIndex = fullTitle.indexOf('__');
        const newTitle = fullTitle.slice(0, (toReplaceIndex - existingChar) - 4);
        const tabName = fullTitle.slice(toReplaceIndex + 2, fullTitle.length);

        return newTitle + char + tabName;
    }

    return (
        <div className={`default-page main-menu hot-food-combo-page`}>
            <HeadTitle
                className={"title"}
                style={{ marginBottom: "22px", alignSelf: "flex-start" }}
                textValue={truncateTitle(title, activeTabName)}
            />
            {!hideNav && <HotFoodTab
                containerSx={{
                    ...!showBarcode && {
                        transform: "translateX(-40px)",
                    },
                    ...(tabs.length > 3 &&
                    {
                        transform: `translateX(${!showBarcode ? -2 : 12}px)`,
                    }),
                }}
                handleTabClick={handleTabClick}
                setTabTitle={setTabLabel}
            />}
            {items.length <= 0 && !showBarcode && (
                <p>No available product...</p>
            )}
            {items.length > 0 && !showBarcode && (
                <HotFoodComboPageItems
                    sortedItems={items}
                    tabName={selectedOption?.name}
                    activeTabName={activeTabName}

                />
            )}

            {showBarcode && <CustomField />}

            <Navigation
                onBack={onBackPage}
                onNext={() => console.log("next")}
                showViewOrderButton={false}
                disableNext
            />
            {promptOperatorId && <OperatorIdPrompt customContinue={handleGetExtendedEserviceMenu} />}
        </div>
    );
}

export default HotFoodComboPage;

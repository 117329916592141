/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";

const MenuItemContent = (props) => {
    const {
        image, name, alt, itemPrice,
    } = props;
    const [imageHasError, setImageHasError] = useState(!image);
    const [imageHash] = useState(Math.floor(Math.random() * 1000000000));
    const nameToRender = name === "" ? null : name;

    const defaultItem = !imageHasError ? (
        <img src={`resources/${image}?${imageHash}`}
            alt={alt}
            onError={() => setImageHasError(true)}
            className="button-image" />
    ) : (
        nameToRender
    );

    return (
        <React.Fragment>
            { defaultItem }
            {itemPrice > 0 && <span className="item-price">
                ${itemPrice.toFixed(2)}
            </span>}
        </React.Fragment>
    );
};

export default MenuItemContent;

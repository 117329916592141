import React, { useState } from "react";

const HeadTitle = (props) => {
    const propsCopy = { ...props };
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const { textValue } = props;

    delete propsCopy.textValue;
    function getDimension() {
        const doc = document.documentElement;
        if (width && height) {
            setWidth("");
            setHeight("");
            return;
        }
        setWidth(doc.scrollWidth);
        setHeight(doc.scrollHeight);
    }
    return (
        <>
            <h2 onClick={getDimension} {...propsCopy}>
                {textValue}
            </h2>
            {width && height && (
                <p
                    style={{
                        position: "absolute",
                        top: "23px",
                        left: "170px",
                        color: "#fff",
                    }}
                >
                    W:{width} - H:{height}
                </p>
            )}
        </>
    );
};

export default HeadTitle;

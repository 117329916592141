import axios from "axios";
import { HIDE_INTERVENTION, SHOW_INTERVENTION } from "../../actions";

export function getExtendedEserviceMenu({
    baseURL,
    requestBody,
    dispatch,
    onGoBack,
}) {
    const prefix = "GET_EXTEND_ESERVICE_MENU_";
    dispatch({ type: prefix + "REQUEST" });
    axios
        .post(`${baseURL}menu/getEServiceExtendedMenu`, requestBody)
        .then((res) => {
            if (res.data.menuItems) {
                const menuItems = res.data.menuItems;
                const tabs = menuItems.filter(item => !item.productId);
                const items = menuItems.filter(item => item.productId);
                dispatch({ type: "HFC_MENU_ITEMS", menuItems: items });
                if (tabs.length) {
                    dispatch({ type: "HFC_SET_TABS", tabs });
                    dispatch({ type: "HFC_ADD_TAB_STACK", tabs });
                }

            }
            dispatch({ type: "HFC_SET_SUB_MENU_ID", subMenuId: requestBody.menuId });
            dispatch({
                type: "SET_PROMPT_OPERATOR_ID",
                promptOperatorId: false,
                pageStackItem: "",
            });
            if (requestBody.operatorAuthId)
                dispatch({ type: "HFC_SET_AUTH_SUCCESS", isSuccessAuth: true });
            dispatch({ type: prefix + "SUCCESS" });
        })
        .catch(({ response }) => {
            console.log('response.........', response);
            if (
                typeof response.data === "object" &&
                response.data.code === "600"
            ) {
                const handleOk = () => {
                    dispatch({ type: HIDE_INTERVENTION });
                    if (typeof onGoBack === "function") onGoBack();
                };
                const children = (
                    <div>
                        <p className="description">{response.data.message}</p>
                        <button type="button" onClick={handleOk}>
                            OK
                        </button>
                    </div>
                );
                dispatch({ type: SHOW_INTERVENTION, children });
                dispatch({
                    type: "SET_PROMPT_OPERATOR_ID",
                    promptOperatorId: false,
                    pageStackItem: "",
                });
            }
            dispatch({ type: prefix + "FAILURE" });
        });
}

export function getProductByPosCode({
    baseURL,
    requestBody,
    dispatch,
    showIntervention,
}) {
    const prefix = "GET_PRODUCT_BY_POS_CODE_";
    dispatch({ type: prefix + "REQUEST" });
    axios
        .post(baseURL + "getProductByPOSCode", requestBody)
        .then((response) => {
            if (response.data) {
                dispatch({ type: "ADD_ORDER_FOOD", item: response.data });
            }
            dispatch({ type: prefix + "SUCCESS" });
            showIntervention();
        })
        .catch((err) => {
            const item = {
                amount: "Unknown",
                posCode: requestBody.posCode,
                productDescription: requestBody.posCode,
                productNumber: requestBody.posCode,
                productFields: [],
                isUnknown: true,
            };
            dispatch({ type: "ADD_ORDER_FOOD", item });
            dispatch({ type: prefix + "FAILURE" });
            showIntervention();
        });
}

/* eslint-disable react/button-has-type */
import React from "react";
import { useHistory } from "react-router";
import CharacterKeyBoard from "../components/VirtualCharKeyboard";
import ElectronicServices from "../libs/ElectronicServices";

function ProductCode() {
    const [productCode, setProductCode] = React.useState("");
    const history = useHistory();
    const handleClick = () => {
        const barcode = {};
        barcode.Barcode = productCode;
        ElectronicServices.AddItem(barcode, null, true);
    };

    const onChange = (item) => {
        setProductCode(item.value);
    };
    return (
        <div style={{ padding: 10 }}>
            <CharacterKeyBoard pageDetail={{ value: "" }} onChange={onChange} title="Enter product Code" />
            <div>
                <button onClick={() => history.goBack()} style={{ padding: "8px 16px", color: "black" }}>prev</button>
                <button onClick={handleClick} style={{ padding: "8px 16px", backgroundColor: "green", color: "white" }}>submit</button>
            </div>
        </div>
    );
}

export default ProductCode;

/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { HIDE_INTERVENTION, SHOW_INTERVENTION } from "../actions";
import {
    ADD_PAGE_STACK,
    exitBrowser,
    RESET_PAGE_STACK,
} from "../actions/defaultPage";
import {
    parseQueryString,
    showViewOrderDetailsButton,
    totalSumValue,
} from "../utils/helper";
import HotFoodQuitInterventionContent from "./interventions/HotFoodQuitInterventionContent";

const Navigation = ({
    disableBack,
    disableNext,
    onBack,
    onNext,
    backLink,
    onExitBrowser,
    hideUrlIndicator,
    hideQuitButton = false,
    showOrderDetailsButton,
    orderedFoods,
    mainWrapperStyle,
    allOrderFoods,
    nextLabel = "",
}) => {
    const { searchParam } = useSelector(({ ui }) => ui);
    const location = window.location.href;
    const dispatch = useDispatch();
    const history = useHistory();
    const loc = useLocation();

    const backComponent = (
        <button onClick={onBack} style={{ display: disableBack ? "none" : "" }}>
            Back
        </button>
    );

    const [buildInfoVisible, setBuildInfoVisible] = useState(false);

    const showBuildInfo = () => {
        setBuildInfoVisible(true);
        setTimeout(() => setBuildInfoVisible(false), 5000);
    };

    const handleOnExitBrowser = () => {
        const handleCancel = () => dispatch({ type: HIDE_INTERVENTION });
        const handleExit = () => {
            handleCancel();
            onExitBrowser();
        };
        if (allOrderFoods.length) {
            dispatch({
                type: SHOW_INTERVENTION,
                children: (
                    <HotFoodQuitInterventionContent
                        orderedFoods={allOrderFoods}
                        handleCancel={handleCancel}
                        handleYes={handleExit}
                    />
                ),
            });
            return;
        }
        onExitBrowser();
    };

    const handleViewOrder = () => {
        const state = {
            prevPage: loc.pathname,
        };
        history.push({ pathname: "hot-food", search: loc.search, state });
    };

    return (
        <div className="navigation" style={mainWrapperStyle}>
            <div className="main-navigation">
                {!hideQuitButton && (
                    <button onClick={handleOnExitBrowser}>Quit</button>
                )}
                {backLink ? backLink(backComponent) : backComponent}
                {!disableNext && (
                    <button onClick={onNext} className="next">
                        {nextLabel || "Next"}
                    </button>
                )}
                {disableNext && showOrderDetailsButton && (
                    <button onClick={handleViewOrder} className="next view-order-details-btn">
                        View Order Details({allOrderFoods.length})
                        {/* {totalSumValue(orderedFoods, "quantity")}) */}
                    </button>
                )}
            </div>
            <div
                className="url-container"
                style={{ visibility: hideUrlIndicator ? "hidden" : "visible" }}
            >
                <p>
                    URL:
                    {location}
                </p>
            </div>
            <div className="build-version-button" onClick={showBuildInfo}>
                <span style={{ display: buildInfoVisible ? "block" : "none" }}>
                    {process.env.REACT_APP_VERSION}
                </span>
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const {
        config: { hideUrlIndicator },
        hotFood: { orderedFoods, hotFoodProductFields, allOrderFoods },
    } = state;
    return {
        hideUrlIndicator: hideUrlIndicator == null ? true : hideUrlIndicator,
        disableNext: ownProps.disableNext,
        disableBack: ownProps.disableBack,
        backLink: ownProps.backLink,
        showOrderDetailsButton: showViewOrderDetailsButton(state, ownProps),
        orderedFoods,
        hotFoodProductFields,
        allOrderFoods,
    };
};

export const onExitBrowser = (dispatch) => {
    dispatch({ type: RESET_PAGE_STACK });
    dispatch({ type: "RESET_HOTFOOD_REDUCER" });
    dispatch({ type: "RESET_PRICE_MANAGEMENT" });
    dispatch(exitBrowser);
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onExitBrowser: () => dispatch(onExitBrowser),
        onBack: ownProps.onBack,
        onNext: ownProps.onNext,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

import { differenceInDays, addDays } from "date-fns";
import { getEServiceMenuItems } from "../../actions/defaultPage";

export function checkIfImageExists(url) {
    const xmlHttpReq = new XMLHttpRequest();
    xmlHttpReq.open("GET", url, false);
    xmlHttpReq.send(null);
    return xmlHttpReq.status === 200;
}

export const validateProductPricingAndRules = (productPricingAndRules, dispatch) => {
    const {
        price, pricingRules, endDate,
    } = productPricingAndRules;
    const hasPricingRules = Object.keys(pricingRules).length;
    // const hasStorePricing = Object.keys(storePricing).length;
    const duration = hasPricingRules ? pricingRules.dayDuration : null;

    if (hasPricingRules && !validatePrice(parseInt(price), pricingRules)) {
        setErrorMessage("Invalid price amount.", dispatch);
        return true;
    }

    if (!endDate) {
        setErrorMessage("Selected date is invalid", dispatch);
        return true;
    }

    if (duration && !validateDuration({ startDate: new Date(), endDate: typeof endDate === "string" ? new Date(endDate) : endDate, duration })) {
        setErrorMessage("Selected date is invalid.", dispatch);
        return true;
    }

    return false;
};

export function validatePrice(price, pricingRules) {
    const { priceMin, priceMax } = pricingRules;
    return price >= priceMin && price <= priceMax;
}

function validateDuration({
    startDate, endDate, duration,
}) {
    const selectedDateDuration = differenceInDays(addDays(endDate, 1), startDate);
    return selectedDateDuration <= duration;
}

function setErrorMessage(errorMessage, dispatch) {
    dispatch({ type: "SET_PRICE_MANAGEMENT_ERROR", errorMessage });
}

export const isPricingManagement = (name = "") => name.split("|").includes("&pricemgmt");

export const findPricemagementDetails = (itemMenu, name) => {
    if (!itemMenu || !itemMenu.length) return null;
    return itemMenu.find((item) => item.name.includes(name));
};

export const getPriceManagementMenu = ({
    setProducts, setProductsCopy, menuId, operatorAuthId, goBack,
}) => (dispatch) => {
    const successCallback = ({ menuItems }) => {
        if (menuItems.length) {
            const sortItem = (itemA, itemB) => parseInt(itemA.displayOrder) - parseInt(itemB.displayOrder);
            const filter = ({ name }) => name;
            const newProducts = Array.isArray(menuItems) ? menuItems.sort(sortItem).filter(filter) : [];
            if (newProducts.length) {
                newProducts.forEach((product, index, arr) => {
                    // eslint-disable-next-line no-param-reassign
                    arr[index].isImageExists = checkIfImageExists(`${window.location.origin}/resources/${product.image}`);
                });
            }
            setProducts(newProducts);
            setProductsCopy(newProducts);
        }
        dispatch({ type: "SET_OPERATOR_AUTH", operatorAuth: operatorAuthId });
        dispatch({ type: "SET_PROMPT_OPERATOR_ID", promptOperatorId: false });
        dispatch({ type: "GET_PRODUCT_FOR_PRICING_SUCCESS" });
    };
    dispatch(getEServiceMenuItems(menuId, successCallback, operatorAuthId, goBack));
};

export function getNameDetails(menuItemName, productInformationList, setPageDetail) {
    // eslint-disable-next-line no-unused-vars
    const [customName, customButtonText, customHeading, customDesciption] = menuItemName.split("|");
    const pricManagement = productInformationList.find((item) => item.menuItemName === customName);
    const { title, description } = pricManagement || { title: "", description: "" };
    setPageDetail({ name: customButtonText || title, description: customHeading || description });
}

export const lastEndDate = (duration) => {
    if (!duration) return null;
    return addDays(new Date(), duration - 1);
};

import { Helpers } from 'common-functions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getDefaultStoreDetails } from '../hotFoodCombo.utils';


function usePrefetchGetExtendedEserviceMenu() {
    const dispatch = useDispatch();
    const { ncrWebServiceUrl, defaultValues } =
        useSelector((state) => state.config);

    async function handlePrefetchGetExtendedEserviceMenu(tabs = []) {
        if (!tabs.length) return;

        const storeDetails = Helpers.getStoreDetails();
        const { storeId, operatorId } = getDefaultStoreDetails(defaultValues);
        const requestBody = {
            storePosId: storeDetails.storeId || storeId,
            operatorId: storeDetails.operatorId || operatorId,
            operatorAuthId: null,
        };


        const promises = tabs.map(tab => {
            requestBody.menuId = tab.menuId;
            return axios.post(`${ncrWebServiceUrl}menu/getEServiceExtendedMenu`, requestBody);
        });

        const result = await Promise.allSettled(promises);
        const fetchedData = result.map(d => {
            if (d?.status === 'fulfilled') {
                const { config: { data: params }, data: { menuItems } } = d.value;
                const param = JSON.parse(params);

                return {
                    menuId: param.menuId,
                    menuItems
                }
            }
        });
        dispatch({ type: "HFC_PREFECTHED_ITEMS", prefetchedItems: fetchedData })
    }
    return { handlePrefetchGetExtendedEserviceMenu }
}

export default usePrefetchGetExtendedEserviceMenu;
import { cloneDeep } from "lodash";

const defaultState = {
    rowsPerPage: 9,
    isSuccessAuth: false,
    tabs: [],
    subMenuId: null,
    menuItems: [],
    tabsLength: 0,
    hideNavigationTab: false,
    hotFoodPageStack: [],
    tabsStack: [],
    prefetchedItems: [],
    orders: [],
    parentOrderID: '',
    hotFoodComboID: '',
};

const hotFoodComboReducer = (state, action) => {
    const hotFoodCombo = state.hotFoodCombo || cloneDeep(defaultState);
    switch (action.type) {
        case "HFC_SET_AUTH_SUCCESS": {
            hotFoodCombo.isSuccessAuth = action.isSuccessAuth;
            return hotFoodCombo;
        }
        case "HFC_SET_TABS": {
            const stateCopy = { ...hotFoodCombo };
            stateCopy.tabs = action.tabs;
            stateCopy.tabsLength = action.tabs?.length || 0;
            return stateCopy;
        }
        case "HFC_SET_HIDE_NAVIGATION_TABS": {
            const stateCopy = { ...hotFoodCombo };
            stateCopy.hideNavigationTab = action.hideNavigationTab;
            return stateCopy;
        }

        case "HFC_SET_SUB_MENU_ID":
            return { ...hotFoodCombo, subMenuId: action.subMenuId };
        case "HFC_ADD_PAGE_STACK": {
            if (hotFoodCombo.hotFoodPageStack.includes(action.menuId))
                return hotFoodCombo;
            return {
                ...hotFoodCombo,
                hotFoodPageStack: [
                    ...hotFoodCombo.hotFoodPageStack,
                    action.menuId,
                ],
            };
        }
        case "HFC_ADD_TAB_STACK": {

            return {
                ...hotFoodCombo,
                tabsStack: [
                    ...hotFoodCombo.tabsStack,
                    action.tabs,
                ],
            };
        }
        case "HFC_REMOVE_PAGE_STACK":
            return {
                ...hotFoodCombo,
                hotFoodPageStack: hotFoodCombo.hotFoodPageStack.slice(0, -1),
            };
        case "HFC_MENU_ITEMS":
            return { ...hotFoodCombo, menuItems: action.menuItems };
        case "HFC_PREFECTHED_ITEMS":
            return { ...hotFoodCombo, prefetchedItems: action.prefetchedItems };

        case "HFC_RESET_STATE": {
            return defaultState;
        }
        case "HFC_SET_ORDERS": {
            return { ...hotFoodCombo, orders: [...hotFoodCombo.orders, action.orders] }
        }

        case "HFC_MAIN_PRODUCT_ID":
            return { ...hotFoodCombo, parentOrderID: action.parentOrderID, hotFoodComboID: action.hotFoodComboID }
        default:
            return hotFoodCombo;
    }
};

export default hotFoodComboReducer;

/* eslint-disable react/jsx-indent-props */
import React from "react";
import { Helpers } from "common-functions";

// eslint-disable-next-line import/no-extraneous-dependencies
import { cloneDeep } from "lodash";
import MainMenu from "../containers/defaultPage/pages/MainMenu";
import ProductFieldEntry from "../containers/defaultPage/pages/ProductFieldEntry";
import OperatorInstructions from "../containers/defaultPage/pages/OperatorInstructions";
import InputValidator from "../libs/InputValidator";

import {
    FILL_UP_BARCODE,
    NEXT_PAGE,
    PREV_PAGE,
    ORDER_INFORMATION_SUCCESS,
    ORDER_INFORMATION_FAILURE,
    REFUND_TRANSACTION_SUCCESS,
    RESET_BARCODE_STATE,
    SCAN_ERROR,
    MENU_ITEM_SUCCESS,
    STAGE_PRODUCT_SUCCESS,
    NEXT_MENU_ITEM,
    PREV_MENU_ITEM,
    INITIATE_DEFAULT_PRODUCT,
    GET_PRODUCT_BY_BARCODE_SUCCESS,
    FILL_UP_PRODUCT_FIELD,
    CUSTOM_PRODUCT_FIELD_ERROR,
    NEXT_ITEM_PAGE,
    PREV_ITEM_PAGE,
    NEXT_PRODUCT_FIELD,
    PREV_PRODUCT_FIELD,
    ADD_PAGE_STACK,
    REMOVE_PAGE_STACK,
    RESET_PAGE_STACK,
    INITIATE_OPERATOR_INSTRUCTIONS,
    RESET_DEFAULT_PAGE_PAGES,
    CLEAR_SWIPE_INPUT_FIELD,
} from "../actions/defaultPage";
import SingleOperatorInstructions from "../containers/defaultPage/pages/SingleOperatorInstructions";
import { DISPLAY_TIME_OUT_TIMER } from "../actions";
import { getUniqueFromArray, mapProductField } from "../utils/helper";

const pages = [
    { step: "MainMenu", title: "MainMenu", component: <MainMenu /> },
    {
        step: "BarcodeNumberEntry",
        title: "BarcodeNumberEntry",
        component: <ProductFieldEntry />,
    },
    {
        step: "OperatorInstructions",
        title: "OperatorInstructions",
        component: <OperatorInstructions />,
    },
];

const defaultState = {
    pages,
    currentIndex: 0,
    barcodeNumber: "",
    scanRetries: 0,
    currentMenuItemStackIndex: 0,
    menuItemStack: {},
    itemPageIndex: 0,
    selectedMenuStack: [],
    menuItemRequestCompleted: 0,
    pageStack: [],
    operatorInstructionIsActive: false,
    displayTimeoutTimer: null,
    displayTimeoutTimerList: [],
    additionalProductFields: {},
    promptOperatorId: false,
    selectedMenuId: null,
    pageStackItem: "",
    initialDefaultProduct: {},
};

const defaultPageReducer = (state, action) => {
    const defaultPage = state.defaultPage || cloneDeep(defaultState);

    switch (action.type) {
        case NEXT_PAGE: {
            return {
                ...defaultPage,
                currentIndex: defaultPage.currentIndex + (action.step || 1),
            };
        }
        case PREV_PAGE: {
            return {
                ...defaultPage,
                currentIndex: defaultPage.currentIndex - (action.step || 1),
            };
        }
        case FILL_UP_BARCODE: {
            let barcodeInput = action.value;
            if (action.source === "keyboard") {
                const { barcodeNumber } = defaultPage;
                if (action.value === "BACKSPACE") {
                    barcodeInput = barcodeNumber.substring(
                        0,
                        barcodeNumber.length - 1,
                    );
                } else if (action.value === "DELETE") {
                    barcodeInput = "";
                } else {
                    barcodeInput = barcodeNumber
                        ? barcodeNumber.concat(barcodeInput)
                        : barcodeInput;
                }
            }

            if (action.validation) {
                const { maxLength } = action.validation;
                if (barcodeInput.length > maxLength) {
                    barcodeInput = defaultPage.barcodeNumber;
                    const target = document.getElementById(action.target);
                    if (target) {
                        target.value = barcodeInput;
                    }
                }
            }

            return {
                ...defaultPage,
                barcodeNumber: barcodeInput,
                orderHasError: false,
            };
        }

        case FILL_UP_PRODUCT_FIELD: {
            let inputValue = action.value;
            const index = defaultPage.currentPageDetailsIndex;
            const productDetails = defaultPage.pageDetails[index];

            const fieldValidator = InputValidator.find(
                (validator) => validator.name === productDetails.jsValidator,
            );

            if (action.source === "keyboard") {
                const { value } = productDetails;
                if (action.value === "BACKSPACE") {
                    inputValue = value.substring(0, value.length - 1);
                } else if (action.value === "DELETE") {
                    inputValue = "";
                } else {
                    inputValue = value ? value.concat(inputValue) : inputValue;
                }
            }

            if (inputValue.length > productDetails.maxLength) {
                inputValue = productDetails.value;
                const target =
                    document.getElementById(action.target) ||
                    document.getElementById(productDetails.name);
                if (target) {
                    target.value = inputValue;
                }
            }

            if (action.keyboardType && action.keyboardType === "ALPHANUMERIC") {
                inputValue = action.value;
            }
            if (
                defaultPage.pageDetails[index].name === "AMOUNT" &&
                inputValue !== ""
            ) {
                inputValue = parseInt(inputValue).toString();
            }

            if (fieldValidator && fieldValidator.format) {
                if (action.target === "AMOUNT") {
                    const lastKey = action.value.substr(-1);
                    inputValue = productDetails.value
                        ? productDetails.value.concat(lastKey)
                        : lastKey;
                }
                productDetails.formattedValue =
                    fieldValidator.format(inputValue);
                productDetails.value = inputValue;
            } else {
                productDetails.value = inputValue;
            }

            productDetails.entryMethod = action.entryMethod;
            defaultPage.pageDetails[index] = productDetails;
            return {
                ...defaultPage,
                currentFieldValue: inputValue,
                hasError: false,
            };
        }

        case NEXT_PRODUCT_FIELD: {
            return {
                ...defaultPage,
                currentPageDetailsIndex:
                    defaultPage.currentPageDetailsIndex + 1,
            };
        }

        case PREV_PRODUCT_FIELD: {
            return {
                ...defaultPage,
                currentPageDetailsIndex:
                    defaultPage.currentPageDetailsIndex - 1,
            };
        }

        case ORDER_INFORMATION_SUCCESS: {
            const { orderInformation } = action;
            const { productFields } = orderInformation;
            const processInstructions = (instruction) =>
                Helpers.isArray(instruction)
                    ? instruction.join("\n\n\n")
                    : instruction;

            const instructions = orderInformation.operatorInstructions || [];
            const operatorInstructionsCopy =
                instructions.map(processInstructions);
            orderInformation.operatorInstructions = operatorInstructionsCopy;

            const sortProductFields = (fieldA, fieldB) =>
                fieldA.displayOrder - fieldB.displayOrder;
            const pageDetails = Helpers.isArray(productFields)
                ? productFields.sort(sortProductFields).map(mapProductField)
                : defaultPage.pageDetails;

            return {
                ...defaultPage,
                pageDetails,
                orderInformation,
                currentPageDetailsIndex: 0,
            };
        }

        case REFUND_TRANSACTION_SUCCESS: {
            return {
                ...defaultPage,
                refundStatus: "SUCCESS",
            };
        }

        case ORDER_INFORMATION_FAILURE: {
            return {
                ...defaultPage,
                orderHasError: true,
                errorMessage: action.errorMessage,
            };
        }

        case CUSTOM_PRODUCT_FIELD_ERROR: {
            const index = action.pageDetailsIndex;
            const productDetails = defaultPage.pageDetails[index];

            productDetails.errorMessage = action.error;
            defaultPage.pageDetails[index] = productDetails;

            return {
                ...defaultPage,
                hasError: true,
            };
        }

        case SCAN_ERROR: {
            return {
                ...defaultPage,
                scanRetries: defaultPage.scanRetries + 1,
            };
        }

        case RESET_BARCODE_STATE: {
            return {
                ...defaultState,
                menuItemRequestCompleted: defaultPage.menuItemRequestCompleted,
                currentIndex: defaultPage.currentIndex,
            };
        }

        case MENU_ITEM_SUCCESS: {
            const { currentMenuItemStackIndex, menuItemRequestCompleted } =
                defaultPage;
            const menuItemStack = {};

            const sortItem = (itemA, itemB) =>
                parseInt(itemA.displayOrder) - parseInt(itemB.displayOrder);
            const sortedItems = Helpers.isArray(action.menuItems)
                ? action.menuItems.sort(sortItem)
                : [];

            menuItemStack[currentMenuItemStackIndex] = sortedItems;
            defaultPage.menuItemStack = {
                ...defaultPage.menuItemStack,
                ...menuItemStack,
            };
            return {
                ...defaultPage,
                menuItemRequestCompleted: menuItemRequestCompleted + 1,
            };
        }

        case STAGE_PRODUCT_SUCCESS: {
            return { ...defaultPage, stageProduct: action.stageProduct };
        }

        case GET_PRODUCT_BY_BARCODE_SUCCESS: {
            return {
                ...defaultPage,
                orderInformation: action.orderInformation,
                stageProduct: action.stageProduct,
                ...(action.productFields && {
                    pageDetails: action.productFields,
                    currentPageDetailsIndex: 0,
                }),
            };
        }

        case NEXT_MENU_ITEM: {
            const { currentMenuItemStackIndex } = defaultPage;

            const selectedMenuStack = [...defaultPage.selectedMenuStack];
            const isDuplicateSelectedMenuID = selectedMenuStack.includes(
                action.selectedMenuId,
            );
            if (!isDuplicateSelectedMenuID)
                selectedMenuStack.push(action.selectedMenuId);

            return {
                ...defaultPage,
                selectedMenuStack,
                currentMenuItemStackIndex: !isDuplicateSelectedMenuID
                    ? currentMenuItemStackIndex + 1
                    : currentMenuItemStackIndex,
                selectedMenuId: action.selectedMenuId,
                itemPageIndex: 0,
            };
        }

        case PREV_MENU_ITEM: {
            const { currentMenuItemStackIndex } = defaultPage;

            const selectedMenuStack = [...defaultPage.selectedMenuStack];
            selectedMenuStack.pop();

            return {
                ...defaultPage,
                selectedMenuStack,
                currentMenuItemStackIndex: currentMenuItemStackIndex - 1,
                itemPageIndex: 0,
            };
        }

        case NEXT_ITEM_PAGE: {
            const { itemPageIndex } = defaultPage;
            return {
                ...defaultPage,
                itemPageIndex: Math.min(
                    itemPageIndex + 1,
                    action.pageStackLength,
                ),
            };
        }

        case PREV_ITEM_PAGE: {
            const { itemPageIndex } = defaultPage;
            return {
                ...defaultPage,
                itemPageIndex: Math.max(itemPageIndex - 1, 0),
            };
        }

        case INITIATE_DEFAULT_PRODUCT: {
            const pageDetails = {
                title: action.title,
                description: action.description,
            };
            return {
                ...defaultPage,
                productName: action.productName,
                transaction: action.transaction,
                defaultProduct: true,
                pageDetails,
                initialDefaultProduct: pageDetails,
            };
        }

        case ADD_PAGE_STACK: {
            // const pageStack = JSON.parse(JSON.stringify(defaultPage.pageStack));
            const pageStack = [...defaultPage.pageStack];
            const isPathAlreadyInclude =
                pageStack.length && pageStack.includes(action.path);
            const defaultPageCopy = { ...defaultPage };
            if (!isPathAlreadyInclude) {
                defaultPageCopy.pageStack = [...pageStack, action.path];
            }
            return defaultPageCopy;
            // return Object.assign(defaultPageCopy, {
            //     ...(!isPathAlreadyInclude && {
            //         // pageStack: pageStack.concat(action.path),
            //         pageStack: [...pageStack, action.path]
            //     }),
            // });
        }

        case REMOVE_PAGE_STACK: {
            const pageStack = JSON.parse(JSON.stringify(defaultPage.pageStack));
            return Object.assign(defaultPage, {
                pageStack: pageStack.filter(
                    (path, index) => index !== pageStack.length - 1,
                ),
            });
        }
        case RESET_PAGE_STACK: {
            return Object.assign(defaultPage, { pageStack: [] });
        }

        case RESET_DEFAULT_PAGE_PAGES: {
            return Object.assign(defaultPage, {
                pages,
                operatorInstructionIsActive: false,
            });
        }

        case INITIATE_OPERATOR_INSTRUCTIONS: {
            const { operatorInstructions, displayOrder, hasProductField } =
                action;
            const productField = {
                step: "BarcodeNumberEntry",
                title: "BarcodeNumberEntry",
                component: <ProductFieldEntry />,
            };
            const pages = [
                {
                    step: "MainMenu",
                    title: "MainMenu",
                    component: <MainMenu />,
                },
            ];

            const operatorInstructionsPages = operatorInstructions.map(
                (instruction) => {
                    return {
                        step: "OperatorInstructions",
                        title: "OperatorInstructions",
                        component: (
                            <SingleOperatorInstructions
                                instruction={instruction}
                            />
                        ),
                    };
                },
            );

            // newPages.splice(+displayOrder - 1, 0, productField);
            const newPages = [...pages, ...operatorInstructionsPages];
            if (hasProductField) {
                newPages.push(productField);
            }
            return Object.assign(defaultPage, {
                pages: newPages,
                operatorInstructionIsActive: true,
            });
        }

        case DISPLAY_TIME_OUT_TIMER: {
            const { displayTimeoutTimer } = action;
            const { displayTimeoutTimerList } = defaultPage;

            return Object.assign(defaultPage, {
                displayTimeoutTimer,
                ...(displayTimeoutTimer && {
                    displayTimeoutTimerList:
                        displayTimeoutTimerList.concat(displayTimeoutTimer),
                }),
                ...(displayTimeoutTimer === null && {
                    displayTimeoutTimerList: [],
                }),
            });
        }

        case CLEAR_SWIPE_INPUT_FIELD: {
            const newDefaultPage = cloneDeep(defaultPage);
            const { currentPageDetailsIndex } = action;
            newDefaultPage.pageDetails[currentPageDetailsIndex].value = "";
            newDefaultPage.currentFieldValue = "";
            return Object.assign(defaultPage, newDefaultPage);
        }

        case "INSERT_PAGE": {
            const defaultPageCopy = cloneDeep(defaultPage);
            const { component, startIndex } = action;
            defaultPageCopy.pages.splice(startIndex, 0, component);
            return Object.assign(defaultPage, defaultPageCopy);
        }

        case "ADDITIONAL_PRODUCT_FIELD": {
            return Object.assign(defaultPage, {
                additionalProductFields: action.field,
            });
        }

        case "SET_PRODUCT_DETAILS_VALUE": {
            const { item } = action;
            const pageDetails = [...defaultPage.pageDetails];

            const pageCopy = pageDetails.map((details) => {
                let detailsCopy = { ...details };
                if (detailsCopy.name === item.name) {
                    detailsCopy = { ...detailsCopy, ...item };
                    return detailsCopy;
                }
                return detailsCopy;
            });

            return {
                ...defaultPage,
                pageDetails: pageCopy,
                currentFieldValue: item.value,
                hasError: false,
            };
        }

        case "SET_PROMPT_OPERATOR_ID":
            return {
                ...defaultPage,
                promptOperatorId: action.promptOperatorId,
                ...(action.pageStackItem && {
                    pageStackItem: action.pageStackItem,
                }),
            };

        case "SET_SELECTED_MENU_ID":
            return { ...defaultPage, selectedMenuId: action.selectedMenuId };

        case "SET_ETENDER_PRODUCT_FIELDS": {
            const newDefaultPage = { ...defaultPage };
            newDefaultPage.currentPageDetailsIndex = 0;
            newDefaultPage.pageDetails = action.pageDetails.map(
                (pageDetail) => {
                    // eslint-disable-next-line no-param-reassign
                    const newObj = { ...pageDetail };
                    newObj.maxLength = pageDetail.fieldMax;
                    newObj.minLength = pageDetail.fieldMin;
                    newObj.value = "";
                    delete newObj.fieldMax;
                    delete newObj.fieldMin;
                    return newObj;
                },
            );
            newDefaultPage.orderInformation = {
                productFields: action.pageDetails,
            };
            newDefaultPage.eTenderTransactionId = action.eTenderTransactionId;
            return newDefaultPage;
        }

        case "SET_HAS_ERROR":
            return Object.assign(defaultPage, { hasError: action.hasError });

        case "CLEAR_BARCODE": {
            const defaultPageCopy = { ...defaultPage };
            defaultPageCopy.barcodeNumber = "";
            delete defaultPageCopy.errorMessage;
            defaultPageCopy.orderHasError = false;
            return defaultPageCopy;
        }

        default:
            return defaultPage;
    }
};

export default defaultPageReducer;

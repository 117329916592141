import "core-js/full";
import "custom-event-polyfill";
import 'react-native-get-random-values';
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import reportWebVitals from "./reportWebVitals";

import getStore from "./store";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
const { persistor, store } = getStore();
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={<p>loading</p>} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
